import cn from "classnames";
import { Card } from "react-bootstrap";

import classes from "./styles.module.scss";

export default function ({ className = "bg-navy", name, children, idx }) {
  return (
    <Card className={cn(className, classes.ServiceCard)} data-bs-theme="dark">
      <Card.Body className="px-4 pt-4">
        <Card.Title className="mb-3">{name}</Card.Title>
        <Card.Text as="div">{children}</Card.Text>
      </Card.Body>
      <Card.Footer className="px-4 pb-4">
        <span>{`${idx + 1}.`.padStart(3, "0")}</span>
      </Card.Footer>
    </Card>
  );
}
